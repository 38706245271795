@import url(https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@500&display=swap);
@font-face {
  font-family: 'SBB';
  src: local('SBB'), url(/static/media/SBB.c4e71362.woff) format('woff');
}
@font-face {
  font-family: 'SBM';
  src: local('SBM'), url(/static/media/SBM.b809fec1.woff) format('woff');
}
@font-face {
  font-family: 'SBL';
  src: local('SBL'), url(/static/media/SBL.dca7d0a3.woff) format('woff');
}

body{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Noto Sans KR', sans-serif;
}
