@font-face {
  font-family: 'SBB';
  src: local('SBB'), url(./assets/font/SBB.woff) format('woff');
}
@font-face {
  font-family: 'SBM';
  src: local('SBM'), url(./assets/font/SBM.woff) format('woff');
}
@font-face {
  font-family: 'SBL';
  src: local('SBL'), url(./assets/font/SBL.woff) format('woff');
}

@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@500&display=swap');

body{
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  font-family: 'Noto Sans KR', sans-serif;
}